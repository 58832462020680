<template>
    <div>
        <Navbar/>
        <b-container class="mt-5">
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="3">
                    <button v-if="clinica=='cqc'|| clinica=='sandbox'" id="admision" @click="switchComponent('HojaDeAdmision')" class="btn_menu_pressed border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-user-plus fa-lg px-2" />
                        Hoja de admisión
                    </button>
                    <button id="regPaciente" @click="switchComponent('Registro')" class="border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-user-plus fa-lg px-2" />
                        Registro de paciente
                    </button>
                    <hr>
                    <b-overlay
                    rounded
                    :show="busy"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button id="atras" @click="imprimirAviso()" class="text-center border_secoundary py-2 mb-4 text-left btn_radius btn-block">
                            <i class="fa fa-download fa-lg px-1" />
                            Imprimir aviso de privacidad
                        </button>
                    </b-overlay>
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="9">
                    <keep-alive>
                        <component v-bind:is="current" ref="components" @savedAdmision="savedAdmision()"></component>
                    </keep-alive>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { Registro, HojaDeAdmision } from '@/components/Paciente'
import { clinica } from '../../../../settings';
import CryptoJS from 'crypto-js'
import { url } from '../../../../settings';
import PDFMerger from 'pdf-merger-js'

export default {
    name: 'Main',
    components: { 
        Navbar, 
        Registro,
        HojaDeAdmision
    },

    data: () => ({
        busy:false,
        current: "",
        clinica: clinica,
        idPerfil: ''
    }),

    methods: {
        async imprimirAviso(){
            this.busy=true
            var este = this;

            let urlim = `${url}/api/aviso-privacidad`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                este.downloadPDF(blob)
            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save("Aviso de privacidad");
                this.busy=false
            } catch (error) {
                console.log(error);
                this.busy=false
            }
        },
        switchComponent(value) {

            if (value==='notas' || value==='consentim') {
                console.log("ns");
            }else{
                localStorage.setItem("view",value)
                this.current = value;
            }
            
            
            const regPaciente = document.getElementById('regPaciente');
            let admision=null;
            if (this.clinica=="cqc" || this.clinica=="sandbox") {
                 admision = document.getElementById('admision');
            }

            switch (value) {
                case 'HojaDeAdmision':
                    admision.classList.add('btn_menu_pressed');
                    regPaciente.classList.remove('btn_menu_pressed');
                    break;

                case 'Registro':
                    this.show = true
                    this.showConsentim = true
                    regPaciente.classList.add('btn_menu_pressed');
                    if (this.clinica=="cqc" || this.clinica=="sandbox") {
                        admision.classList.remove('btn_menu_pressed');
                    }
                    break;

                default:
                break;
            }
        },
        savedAdmision(){
            this.switchComponent('Registro')
            setTimeout(() => {
                this.$refs.components.savedAdmisionBol()
                
            }, 300);
        }

    },
    beforeMount(){
    },

    mounted() {
        if (this.clinica=="chlo") {
            this.switchComponent('Registro')
        }else{
            this.switchComponent('HojaDeAdmision')
        }
        /* Id del perfil */
        var ide = localStorage.getItem("perfil");
        this.idPerfil =  CryptoJS.AES.decrypt(ide, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);

    },
    destroyed() {  
        this.$destroy();
    }

    
}
</script>

<style scoped>
    .btn_size_vw {
        width: 40vw;
    }

</style>